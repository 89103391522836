<template>
<input type="text" id="testid"/>
  <form @submit.prevent="onSubmit">
    <div class="radioContent">
      <label for="cc-name" class="form-field-title">
        <div id="cc-name" class="form-field" />
      </label>
      <label for="cc-number" class="form-field-title">
        <div id="cc-number" class="form-field" />
      </label>
      <div class="form-field-group">
        <label for="cc-expiration-date" class="form-field-title">
          <div id="cc-expiration-date" class="form-field" />
        </label>
        <label for="cc-cvv" class="form-field-title">
          <div id="cc-cvv" class="form-field" />
        </label>
      </div>
    </div>
    <div class="button-wrapper">
      <button type="submit" class="form-button">Submit</button>
    </div>
  </form>
</template>

<script>
import { loadVGSCollect } from '@vgs/collect-js';
import { toRaw } from 'vue';
export default {
  props: {
    vaultId: {
      type: String, // Change the type according to the data type of the vaultId
      required: true,
    },
  },
  data() {
    return {
      collectForm: {},
    };
  },
  async mounted() {

    const input = document.getElementById("testid");
    input.value = localStorage.getItem('testid');

    this.collectForm = await loadVGSCollect({
      vaultId: localStorage.getItem('vaultId'), //tntq4dwvhri required
      environment: 'sandbox',
      version: '2.18.0',
    }).catch(() => {
      console.log('load error');
    });

    this.collectForm = this.collectForm.init(function (state) {
      console.log(state);
    });

    const styles = {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI"',
      width: '150px',
      height: '50px',
    };
    const collectFromtoRaw = toRaw(this.collectForm);
    collectFromtoRaw.field('#cc-name', {
      type: 'text',
      name: 'card-name',
      placeholder: 'Cardholder Name',
      validations: ['required'],
      css: styles,
    });
    collectFromtoRaw.field('#cc-number', {
      type: 'card-number',
      name: 'card-number',
      placeholder: 'Card Number',
      showCardIcon: true,
      validations: ['required', 'validCardNumber'],
      css: styles,
    });
    collectFromtoRaw.field('#cc-expiration-date', {
      type: 'card-expiration-date',
      name: 'card-expiration-date',
      placeholder: 'MM/YYYY',
      validations: ['required', 'validCardExpirationDate'],
      css: styles,
      serializers: [
        {
          name: 'separate',
          options: { monthName: 'exp_month', yearName: 'exp_year' },
        },
      ],
      yearLength: '4',
    });
    collectFromtoRaw.field('#cc-cvv', {
      type: 'card-security-code',
      name: 'cvc',
      placeholder: 'CVC',
      maxLength: 3,
      validations: ['required', 'validCardSecurityCode'],
      css: styles,
    });
  },
  methods: {
    onSubmit(e) {
      alert(`Vault ID: ${this.vaultId}`);
      e.preventDefault();


      //externalFunction('callback');

      toRaw(this.collectForm).submit(
        '/post',
        {
          method: 'post',
        },
        (status, data) => {
          console.log(data);
        }
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  font-family: 'Helvetica Neue', Helvetica;
  font-size: 15px;
  font-variant: normal;
  padding: 0;
  margin: 0;
}

pre {
  color: #31325f;
  font-family: 'Consolas', monospace;
  font-weight: 200;
}

html {
  height: 100%;
}

.form-field {
  width: 250px;
  height: 50px;
  margin-bottom: 1rem;
  padding: 0.5rem;
}

body {
  min-height: 100%;
  padding: 30px;
}

iframe {
  width: 100%;
  height: 100%;
  border: 1px solid lightgrey;
  margin-bottom: 1rem;
}
</style>
