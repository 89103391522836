<template>
  <div id="app">
    <VGS :vaultId="vaultId" />
  </div>
</template>

<script>
import VGS from './vgs/VGS.vue';

export default {
  name: 'App',
  components: {
    VGS,
  },
  data() {
    return {
      vaultId: '', // Initialize vaultId to an empty string
    };
  },
  created() {
    // Retrieve the vaultId from localStorage when the component is created
    this.vaultId = localStorage.getItem('vaultId') || ''; // If not found, set it to an empty string
  },
};
</script>

<style>
/* Your styles here */
</style>
